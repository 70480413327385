/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../css/global.scss');
//require('../../node_modules/bootstrap/dist/css/bootstrap.min.css')
//require('../css/bootstrap_theme.css');
//require('bootstrap/dist/css/bootstrap.css');
// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

require('select2/dist/css/select2.css');
require('ekko-lightbox/dist/ekko-lightbox.css');
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;

require('bootstrap');
//require('cropper');
require('popper.js');
require('select2');
require('ekko-lightbox');
global.moment = require('moment');
// or you can include specific pieces
//require('bootstrap/js/dist/tooltip');
//require('bootstrap/js/dist/popover');

$(document).ready(function() {
//    $('[data-toggle="popover"]').popover();
});

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
